import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ReactNotification from 'react-notifications-component'
import Thankyou from "../../components/Thankyou"

const ThankYouPage = () => {
  return(
    <Layout>
      <ReactNotification />
      <SEO title="Thank You" path="/thank-you"/>
      <Thankyou />
    </Layout>
  )
}

export default ThankYouPage
